
body {
  margin: 0;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overFlow-class{
  overflow: hidden;
}

.custom-file-button input[type="file"] {
  margin-left: -2px !important;
}

.custom-file-button input[type="file"]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type="file"]::file-selector-button {
  display: none;
}

.custom-file-button:hover label {
  background-color: #dde0e3;
  cursor: pointer;
}
.cursor-pointer{
  cursor: pointer
}

.backButton{
  border-color: rgb(99 102 241/1);
}
.date-picker {
  width: 115px;
}